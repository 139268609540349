import Modal from "../Common/Modal/Modal";
import CheckboxTable from "./CheckboxTable";
import HeaderTable from "./HeaderTable";
import Logo from "../../assets/logo-header.png";

interface TableModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  imageContainer: any;
  modalData: any;
}

const TableModal: React.FC<TableModalProps> = ({
  showModal,
  setShowModal,
  imageContainer,
  modalData,
}) => {
  const modalActionButtonsList = [
    {
      buttonText: "Merge",
      backgroundColor: "#003a75",
    },
    { buttonText: "Split", backgroundColor: "#003a75" },
    { buttonText: "Add Row", backgroundColor: "#003a75" },
    { buttonText: "Add Column", backgroundColor: "#003a75" },
    { buttonText: "Copy", backgroundColor: "#003a75" },
    { buttonText: "Paste", backgroundColor: "#003a75" },
    { buttonText: "Deselect All", backgroundColor: "#003a75" },
    { buttonText: "Omit", backgroundColor: "#003a75" },
    { buttonText: "Clear Table", backgroundColor: "#b30037" },
    { buttonText: "Confirm Review", backgroundColor: "#ffb200" },
  ];
  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      children={
        <div className="table-modal-body">
          <div className="table-modal-header">
            <div className="modal-logo-title">
              <img
                src={Logo}
                alt="merchandize-wizard-logo"
                className="app-logo"
              ></img>
              <div className="document-header" style={{ border: "none" }}>
                <h2>
                  <span style={{ color: "#003a75" }}>Merchandize</span>{" "}
                  <span> Wizard </span>
                </h2>
              </div>
            </div>
            <div className="modal-action-buttons-container">
              {modalActionButtonsList?.map((button) => (
                <button
                  style={{ backgroundColor: `${button.backgroundColor}` }}
                  className="modal-action-buttons"
                >
                  {button.buttonText}
                </button>
              ))}
            </div>
          </div>
          <div className="modal-image-container">
            {imageContainer}
            <div style={{ width: "55%", overflow: "scroll" }}>
              <div className="icon-title-div">
                <i className="fa fa-list"></i>
                <p>Identify Column Headers</p>
              </div>
              <HeaderTable tableData={modalData} />
              <div className="icon-title-div">
                <i className="fa fa-table"></i>
                <p>Table Data Rows</p>
              </div>
              <CheckboxTable tableData={modalData} />
            </div>
          </div>
        </div>
      }
    ></Modal>
  );
};

export default TableModal;
