import Logo from "../../../assets/logo-header.png";
import Avatar from "../../../assets/avatar.png";
import "./Navbar.css";

export const Navbar = () => {
  return (
    <header className="menu">
      <a href="/">
        <img src={Logo} alt="logo" className="app-logo"></img>
      </a>
      <div className="user-menu">
        <img src={Avatar} alt="user" className="user-image"></img>
        <p>tradesunMaker</p>
      </div>
      <nav className="nav">
        <a href="/">Work queue</a>
        <a href="/">Inquiry</a>
      </nav>
    </header>
  );
};
