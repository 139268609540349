import React, { useState } from "react";
import { useTable, usePagination } from "react-table";
import "./WorkQueue.css";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../assets/dropdown.svg";
import { Stepper } from "../Common/Stepper/Stepper";

const data = [
  {
    referenceNo: "14JUNT2",
    status: "NEW",
    branch: "UNITED STATES",
    product: "IMPORT COLLECTION",
    productSubType: "DOCUMENTARY COLLECTION",
    transactionType: "ISSUANCE/ADVISE",
    instrumentRef: "14JUNT2",
    applicant: "",
    beneficiary: "",
    maker: "",
    checker: "",
    lastModified: "06/19/2024 11:20",
  },
  {
    referenceNo: "TEST-OPI",
    status: "REJECTED BY CHECKER",
    branch: "UNITED STATES",
    product: "EXPORT LC",
    productSubType: "COMMERCIAL LETTER OF CREDIT",
    transactionType: "DOCUMENT PRESENTATION",
    instrumentRef: "TEST-OPI",
    applicant: "",
    beneficiary: "",
    maker: "",
    checker: "",
    lastModified: "06/19/2024 11:19",
  },
  // Add more data as needed
];

const filters: any = [
  { inputType: "textfield", label: "Reference No" },
  { inputType: "textField", label: "Instrument Ref" },
  { inputType: "dropdown", label: "Branch" },
  { inputType: "dropdown", label: "Product" },
  { inputType: "dropdown", label: "Product Sub-Type" },
  { inputType: "dropdown", label: "Transaction Type" },
  { inputType: "textField", label: "Transaction Amount" },
  { inputType: "dropdown", label: "Status" },
  { inputType: "calendar", label: "Created date" },
  { inputType: "calendar", label: "Modified date" },
  { inputType: "dropdown", label: "Page limit" },
];

const columns: any = [
  { Header: "Reference No.", accessor: "referenceNo" },
  { Header: "Status", accessor: "status" },
  { Header: "Branch", accessor: "branch" },
  { Header: "Product", accessor: "product" },
  { Header: "Product Sub-Type", accessor: "productSubType" },
  { Header: "Transaction Type", accessor: "transactionType" },
  { Header: "Instrument Ref", accessor: "instrumentRef" },
  { Header: "Applicant", accessor: "applicant" },
  { Header: "Beneficiary", accessor: "beneficiary" },
  { Header: "Maker", accessor: "maker" },
  { Header: "Checker", accessor: "checker" },
  { Header: "Last Modified", accessor: "lastModified" },
];

const WorkQueue = () => {
  const navigate = useNavigate();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        initialState: {},
      },
      usePagination
    );
  const [rowSelected, setRowSelected] = useState(false);

  const renderInput = (filterType: any) => {
    switch (filterType) {
      case "textfield":
      case "textField":
        return <input type="text" />;
      case "dropdown":
        return (
          <div className="dropdown-input">
            <select>
              <option value=""></option>
            </select>
            <img src={Dropdown} alt="dropdown"></img>
          </div>
        );
      case "calendar":
        return <input type="date" />;
      default:
        return null;
    }
  };

  return (
    <div className="work-queue">
      <Stepper activeStepperIndex={0} />

      <div className="top-container">
        <div className="filter-container">
          <div className="filters">
            {filters.map((filter: any) => (
              <div className="input-field">
                <label className="filter-label">{filter.label}</label>

                {renderInput(filter.inputType)}
              </div>
            ))}
          </div>
          <div className="filter-action-buttons">
            <button className="button">Search</button>
            <button className="button">Reset</button>
          </div>
        </div>
        <div className="header">
          <div className="pagination">
            <button>{"<<"}</button>
            <button>{"<"}</button>
            <span>
              Page
              <strong>1 of 10</strong>{" "}
            </span>
            <button>{">"}</button>
            <button>{">>"}</button>
          </div>
          <div className="action-buttons">
            <button className="button">
              <span>Download to Excel</span> <i className="fa fa-download"></i>
            </button>
            <button className="button">Configuration Console</button>
            <button className="button">Manage Transactions</button>
            {rowSelected && <button className="button">Go</button>}

            <button className="button">Analytics</button>
            <button className="button" onClick={() => navigate("/new")}>
              New Transaction
            </button>
            <button className="button" onClick={() => navigate("/ask")}>
              Upload and Ask
            </button>
            {rowSelected && (
              <button className="button">View Audit Trail</button>
            )}
          </div>
        </div>
      </div>

      <div className="table-div">
        <table {...getTableProps()} className="table">
          <thead className="work-queue-thead">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                <th></th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td>
                    <i className="fa fa-lock"></i>
                  </td>
                  <td>
                    <input
                      type="radio"
                      name="selectRow"
                      className="radiobutton"
                      onClick={() => setRowSelected(true)}
                    />
                  </td>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td>
                    <i className="fa fa-pencil"></i>
                  </td>
                  <td>
                    <i className="fa fa-archive"></i>
                  </td>
                  <td>
                    <i className="fa fa-trash"></i>
                  </td>
                  <td>
                    <i className="fa fa-plus-circle"></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkQueue;
