import { useEffect, useRef, useState } from "react";
import "./DocumentViewer.css";
import { useLocation } from "react-router";
import { Stepper } from "../Common/Stepper/Stepper";
import * as XLSX from "xlsx";
import ActionBar from "./ActionBar";
import Thumbnail from "./Thumbnail";
import ImageContainer from "./ImageContainer";
import TableView from "./TableView";
import TableModal from "./TableModal";
import DefaultView from "./DefaultView";
import AccordionView from "./AccordionView";

type ImageDimensions = {
  width?: number;
  height?: number;
  naturalWidth?: number;
  naturalHeight?: number;
};

const DocumentViewer = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [boxes, setBoxes] = useState<any[]>([]);
  const [hoveredKey, setHoveredKey] = useState("");
  const [focusedKey, setFocusedKey] = useState("");
  const [editedValues, setEditedValues] = useState<{ [key: string]: string }>(
    {}
  );

  const [selectionStart, setSelectionStart] = useState<{
    x: number | 0;
    y: number | 0;
  } | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<{
    x: number | 0;
    y: number | 0;
  } | null>(null);
  const [selectionPageNo, setSelectionPageNo] = useState<number>();
  const imgRef = useRef<HTMLImageElement[]>([]);
  const [extractedText, setExtractedText] = useState("");
  const [selectingText, setSelectingText] = useState(false);
  const [zoomCalled, setZoomCalled] = useState(false);
  const [toggleAccordion, setToggleAccordion] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toggleTableAccordion, setToggleTableAccordion] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentDocument, setCurrentDocument] = useState(0);

  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  });

  useEffect(() => {
    // Initialize refs array to match the number of images
    imgRef.current = imgRef.current.slice(
      0,
      location.state.data[currentDocument].images ?? 1
    );
  }, [currentDocument, location.state.data]);

  const handleMouseDown = (e: React.MouseEvent, pageNo: number) => {
    e.preventDefault();
    if (selectingText) {
      setSelectingText(false);
      setSelectionPageNo(undefined);
      extractSelectedText(pageNo);
      setSelectionStart(null);
      setSelectionEnd(null);
      return;
    }
    if (imgRef.current) {
      setSelectingText(true);
      const { left, top } = imgRef.current[pageNo - 1].getBoundingClientRect();

      setSelectionStart({
        x: e.clientX - left,
        y: e.clientY - top,
      });

      // Reset end selection on new drag
      setSelectionEnd(null);
    }
  };

  const handleMouseMove = (e: React.MouseEvent, pageNo: number) => {
    if (imgRef.current && selectionStart) {
      const { left, top } = imgRef.current[pageNo - 1].getBoundingClientRect();
      setSelectionEnd({
        x: e.clientX - left,
        y: e.clientY - top * pageNo,
      });
    }
  };

  // Function to check if a vertex is within the selected area
  function vertexWithinArea(vertex: { x: number; y: number }): boolean {
    const { x, y } = vertex;
    const startX = Math.min(selectionStart!.x, selectionEnd!.x);
    const startY = Math.min(selectionStart!.y, selectionEnd!.y);
    const height = Math.abs(selectionEnd!.y - selectionStart!.y);
    const width = Math.abs(selectionEnd!.x - selectionStart!.x);

    return (
      x >= startX && x <= startX + width && y >= startY && y <= startY + height
    );
  }

  const transformCoordinates = (
    vertex: {
      x: number;
      y: number;
    },
    pageNo: number
  ): {
    x: number;
    y: number;
  } => {
    const { x, y } = vertex;
    let { naturalWidth, naturalHeight, width, height } = imageDimensions;
    let transformedX = 0;
    let transformedY = 0;
    if (width && height && naturalHeight && naturalWidth) {
      const scaleX = width / naturalWidth;
      const scaleY = height / naturalHeight;
      transformedX = x * scaleX;
      transformedY = y * scaleY + height! * (pageNo - 1);
    }
    return { x: transformedX, y: transformedY };
  };

  const extractSelectedText = (pageNo: number) => {
    if (!selectionStart || !selectionEnd) return;

    let concatenatedString = "";

    location.state.data[currentDocument].ocr
      .find((ocr: any) => ocr.pageNo === pageNo)
      .ocr.forEach((ocrObject: any) => {
        const { boundingPoly, description } = ocrObject;
        const { vertices } = boundingPoly;

        // Transform vertices to match selected area coordinates
        const transformedVertices = vertices?.map((vertex: any) =>
          transformCoordinates(vertex, pageNo)
        );

        // Check if any transformed vertex of the boundingPoly is within the selected area
        const vertexWithinSelectedArea = transformedVertices.some(
          (vertex: any) => vertexWithinArea(vertex)
        );

        if (vertexWithinSelectedArea) {
          concatenatedString += description + " ";
        }
      });

    setExtractedText(concatenatedString);
  };

  const handleImageLoad = (e: any, pageNo: number) => {
    const { naturalWidth, naturalHeight, width, height } = e.target;
    setImageDimensions({ naturalWidth, naturalHeight, width, height });
  };

  const totalPages = 9;

  useEffect(() => {
    const extractedBoxes = [];
    for (const key in location.state.data[currentDocument].extraction) {
      extractedBoxes.push({
        vertices:
          location.state.data[currentDocument].extraction[key].coordinates
            ?.vertices,
        pageNo:
          location.state.data[currentDocument].extraction[key].pageNo ?? 1,
        id: key,
      });
    }
    setBoxes(extractedBoxes);
  }, [currentDocument]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const exportToExcel = () => {
    const data: any[] = [];

    for (const key in location.state.data[currentDocument].extraction) {
      if (
        typeof location.state.data[currentDocument].extraction[key].value !==
        "string"
      ) {
        location.state.data[currentDocument].extraction[key].value.forEach(
          (value: any) => {
            data.push({
              tradesunLabel: key,
              documentLabel: key,
              documentValue: value,
            });
          }
        );
      } else {
        data.push({
          tradesunLabel: key,
          documentLabel: key,
          documentValue:
            editedValues[key] ??
            location.state.data[currentDocument].extraction[key].value,
        });
      }
    }
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      location.state.data[currentDocument].fileName.split(".")[0] + ".xlsx"
    );
  };

  useEffect(() => {
    const handleResize = (e: any) => {
      const img = document.querySelector(`#doc-preview-0-${currentDocument}`);

      if (img) {
        const { width, height } = img.getBoundingClientRect();
        setImageDimensions((prevDimensions) => ({
          ...prevDimensions,
          ...{ width, height },
        }));
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentDocument]);

  const calculateBoxStyle = (vertices: any[], pageNo: number) => {
    // Check if the currentPage key exists in imageDimensions
    // Access image dimensions for the current page
    let { naturalWidth, naturalHeight, width, height } = imageDimensions;
    let scaleX = 0;
    let scaleY = 0;
    if (width && height && naturalHeight && naturalWidth) {
      scaleX = width / naturalWidth;
      scaleY = height / naturalHeight;
    }
    const scaledVertices = vertices?.map((v) => ({
      x: v.x * scaleX,
      y: v.y * scaleY + height! * (pageNo - 1),
    }));

    const xCoordinates = scaledVertices?.map((v) => v.x);
    const yCoordinates = scaledVertices?.map((v) => v.y);

    const left = xCoordinates && Math.min(...xCoordinates);
    const top = yCoordinates && Math.min(...yCoordinates);
    const boxWidth = xCoordinates && Math.max(...xCoordinates) - left;
    const boxHeight = yCoordinates && Math.max(...yCoordinates) - top;

    return { top, left, width: boxWidth, height: boxHeight };
  };
  const handleCellEdit = (label: string, newValue: string) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [label]: newValue,
    }));
  };

  const handleAccordionCellEdit = (
    label: string,
    newValue: string,
    index: number
  ) => {
    location.state.data[currentDocument].extraction[label].value[index] =
      newValue;
  };

  const handleDocumentLabelEdit = (obj: any, label: string, newKey: string) => {
    if (label !== newKey && obj.hasOwnProperty(label)) {
      const keys = Object.keys(obj);
      const result: any = {};

      keys.forEach((key) => {
        if (key === label) {
          result[newKey] = obj[key];
        } else {
          result[key] = obj[key];
        }
      });

      for (let key in obj) {
        delete obj[key];
      }

      Object.assign(obj, result);
    }
    return obj;
  };

  const addRow = () => {
    const newKey = `new_key_${Object.keys(location.state.data[currentDocument].extraction).length + 1
      }`;
    const newExtraction = {
      [newKey]: {
        value: "",
        coordinates: { vertices: [] },
      },
      ...location.state.data[currentDocument].extraction,
    };
    location.state.data[currentDocument].extraction = newExtraction;
    setEditedValues((prevValues) => ({
      [newKey]: "",
      ...prevValues,
    }));
  };

  const deleteRow = (label: any) => {
    const newExtraction = {
      ...location.state.data[currentDocument].extraction,
    };
    delete newExtraction[label];

    const newEditedValues = { ...editedValues };
    delete newEditedValues[label];

    location.state.data[currentDocument].extraction = newExtraction;
    setEditedValues(newEditedValues);
  };
  const deleteAccordionRow = (label: string, index: number) => {
    const newArray = [
      ...location.state.data[currentDocument].extraction[label].value,
    ];

    newArray.splice(index, 1);

    location.state.data[currentDocument].extraction[label].value = newArray;
  };

  return (
    <div className="document-viewer-body">
      {showModal && (
        <TableModal
          showModal={showModal}
          setShowModal={setShowModal}
          imageContainer={
            <ImageContainer
              handleImageLoad={handleImageLoad}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              imgRef={imgRef}
              imgSrc={location.state.data[currentDocument].image}
              imgDocType={location.state.data[currentDocument].docType}
              selectionEnd={selectionEnd}
              selectionStart={selectionStart}
              boxes={boxes}
              calculateBoxStyle={calculateBoxStyle}
              hoveredKey={hoveredKey}
              setZoomCalled={setZoomCalled}
              isZoomCalled={zoomCalled}
              selectionPageNo={selectionPageNo}
              location={location}
              currentDocument={currentDocument}
            />
          }
          modalData={modalData}
        />
      )}
      <Stepper activeStepperIndex={2} />
      <div className="document-viewer">
        <div className="document-container">
          <ImageContainer
            handleImageLoad={handleImageLoad}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            imgRef={imgRef}
            imgSrc={location.state.data[currentDocument].image}
            imgDocType={location.state.data[currentDocument].docType}
            selectionEnd={selectionEnd}
            selectionStart={selectionStart}
            boxes={boxes}
            calculateBoxStyle={calculateBoxStyle}
            hoveredKey={hoveredKey}
            setZoomCalled={setZoomCalled}
            isZoomCalled={zoomCalled}
            selectionPageNo={selectionPageNo}
            location={location}
            currentDocument={currentDocument}
          />

          <div className="document-sidebar">
            <ActionBar
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              exportToExcel={exportToExcel}
            />
            <Thumbnail
              imageData={location.state.data}
              setCurrentDocument={setCurrentDocument}
              currentDocument={currentDocument}
            />

            <div className="document-table">
              <table>
                <thead>
                  <tr>
                    <th className="header-1">
                      <i
                        style={{ marginRight: "8px" }}
                        onClick={addRow}
                        className="fa fa-plus-square"
                      ></i>
                      Document Label
                    </th>
                    <th className="header-2">TradeSun Label</th>
                    <th className="header-3">Document Value</th>
                  </tr>
                </thead>
                <tbody key={currentDocument}>
                  {Object.keys(
                    location.state.data[currentDocument]?.extraction ?? {}
                  )?.map((label, index) =>
                    typeof location.state.data[currentDocument].extraction[
                      label
                    ].value !== "string" && label !== "tables" ? (
                      <AccordionView
                        key={label + currentDocument}
                        hoveredKey={hoveredKey}
                        setHoveredKey={setHoveredKey}
                        label={label}
                        index={index}
                        handleDocumentLabelEdit={handleDocumentLabelEdit}
                        setFocusedKey={setFocusedKey}
                        focusedKey={focusedKey}
                        setExtractedText={setExtractedText}
                        extractedText={extractedText}
                        location={location}
                        setToggleAccordion={setToggleAccordion}
                        toggleAccordion={toggleAccordion}
                        handleAccordionCellEdit={handleAccordionCellEdit}
                        deleteAccordionRow={deleteAccordionRow}
                        currentDocument={currentDocument}
                      />
                    ) : label === "tables" ? (
                      <TableView
                        key={label + currentDocument}
                        location={location}
                        label={label}
                        index={index}
                        toggleTableAccordion={toggleTableAccordion}
                        setToggleTableAccordion={setToggleTableAccordion}
                        setShowModal={setShowModal}
                        setModalData={setModalData}
                        currentDocument={currentDocument}
                      />
                    ) : (
                      <DefaultView
                        key={label + currentDocument}
                        hoveredKey={hoveredKey}
                        setHoveredKey={setHoveredKey}
                        label={label}
                        index={index}
                        handleDocumentLabelEdit={handleDocumentLabelEdit}
                        setFocusedKey={setFocusedKey}
                        focusedKey={focusedKey}
                        setExtractedText={setExtractedText}
                        handleCellEdit={handleCellEdit}
                        deleteRow={deleteRow}
                        extractedText={extractedText}
                        location={location}
                        editedValues={editedValues}
                        currentDocument={currentDocument}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;
