interface AccordionViewProps {
  hoveredKey: string;
  setHoveredKey: (hoveredKey: string) => void;
  label: any;
  index: number;
  handleDocumentLabelEdit: (
    extraction: any,
    label: any,
    innerText: any
  ) => void;
  setFocusedKey: (focusedKey: string) => void;
  focusedKey: string;
  setExtractedText: (extractedText: string) => void;
  extractedText: string;
  location: any;
  setToggleAccordion: (toggleAccordion: boolean) => void;
  toggleAccordion: boolean;
  handleAccordionCellEdit: (label: any, innerText: any, index: any) => void;
  deleteAccordionRow: (label: any, index: any) => void;
  currentDocument: number;
}

const AccordionView: React.FC<AccordionViewProps> = ({
  hoveredKey,
  setHoveredKey,
  label,
  index,
  handleDocumentLabelEdit,
  setFocusedKey,
  focusedKey,
  setExtractedText,
  extractedText,
  location,
  setToggleAccordion,
  toggleAccordion,
  handleAccordionCellEdit,
  deleteAccordionRow,
  currentDocument,
}) => {
  return toggleAccordion ? (
    location.state.data[currentDocument].extraction[label].value?.map(
      (v: string, i: number) => (
        <tr
          onMouseLeave={() => {
            hoveredKey === v && setHoveredKey("");
          }}
          onMouseEnter={() => setHoveredKey(v)}
          key={`${label}-${index}`}
          className="document-viewer-row"
          onClick={() => {
            document.getElementById(`doc-preview-${index}-${currentDocument}-box`)?.scrollIntoView({ behavior: 'smooth' })
          }}
        >
          <td
            contentEditable
            suppressContentEditableWarning
            key={`${label}-${index}-doc`}
            onBlur={(e: any) => {
              handleDocumentLabelEdit(
                location.state.data[currentDocument].extraction,
                label,
                e.target.innerText
              );
            }}
          >
            {i === 0 && (
              <i
                className={"fa fa-angles-down"}
                key={`${label}-${index}-chevron-icon`}
                onClick={() => setToggleAccordion(!toggleAccordion)}
              ></i>
            )}
            {label.startsWith("new_key") || i !== 0 ? "" : label}
          </td>
          <td key={`${label}-${index}-tradesun`}>
            {label.startsWith("new_key") || i !== 0 ? "" : label}
          </td>
          <td
            contentEditable={true}
            onClick={() => setFocusedKey(v)}
            suppressContentEditableWarning
            key={`${label}-${index}-doc-val`}
            onBlur={(e) => {
              focusedKey === v && setFocusedKey("");
              setExtractedText("");
              handleAccordionCellEdit(label, e.target.innerText, i);
            }}
          >
            {extractedText !== "" && focusedKey === v ? extractedText : v}
          </td>
          {hoveredKey === v && (
            <i
              className="fa fa-trash"
              key={`${label}-${index}-icon`}
              onClick={() => deleteAccordionRow(label, i)}
            ></i>
          )}
        </tr>
      )
    )
  ) : (
    <tr
      onMouseLeave={() => {
        hoveredKey === label && setHoveredKey("");
      }}
      onMouseEnter={() => setHoveredKey(label)}
      key={`${label}-${index}`}
      className="document-viewer-row"
      onClick={() => {
        document.getElementById(`doc-preview-${index}-${currentDocument}-box`)?.scrollIntoView({ behavior: 'smooth' })
      }}
    >
      <td
        contentEditable
        suppressContentEditableWarning
        key={`${label}-${index}-doc`}
        onBlur={(e: any) => {
          handleDocumentLabelEdit(
            location.state.data[currentDocument].extraction,
            label,
            e.target.innerText
          );
        }}
      >
        <i
          className={"fa fa-angles-right"}
          key={`${label}-${index}-chevron-icon`}
          onClick={() => setToggleAccordion(!toggleAccordion)}
        ></i>

        {label.startsWith("new_key") ? "" : label}
      </td>
      <td key={`${label}-${index}-tradesun`}>
        {label.startsWith("new_key") ? "" : label}
      </td>
      <td
        contentEditable={true}
        onClick={() => setFocusedKey(label)}
        suppressContentEditableWarning
        key={`${label}-${index}-doc-val`}
        onBlur={(e) => {
          focusedKey === label && setFocusedKey("");
          setExtractedText("");
          handleAccordionCellEdit(label, e.target.innerText, 0);
        }}
      >
        {extractedText !== ""
          ? extractedText
          : `${location.state.data[currentDocument].extraction[label].value[0]}, ${location.state.data[currentDocument].extraction[label].value[1]},...`}
      </td>
      {hoveredKey === label && (
        <i
          className="fa fa-trash"
          key={`${label}-${index}-icon`}
          onClick={() => {
            const newExtraction = {
              ...location.state.data[currentDocument].extraction,
            };
            delete newExtraction[label];

            location.state.data[currentDocument].extraction = newExtraction;
          }}
        ></i>
      )}
    </tr>
  );
};
export default AccordionView;
