import { useNavigate } from "react-router";

interface ActionBarProps {
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
  exportToExcel: () => void;
}

const ActionBar: React.FC<ActionBarProps> = ({
  handlePageChange,
  currentPage,
  exportToExcel,
}) => {
  const navigate = useNavigate();
  return (
    <div className="document-footer">
      <div className="pagination-div">
        <button
          className="pagination-buttons"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <i className="fa fa-arrow-left"></i>
        </button>

        <span>Page 1/1</span>
        <button
          className="pagination-buttons"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <i className="fa fa-arrow-right"></i>
        </button>
        <button className="pagination-buttons">
          <i className="fa fa-filter"></i>
        </button>
      </div>

      <div className="pagination-div">
        <button className="pagination-buttons">
          <i className="fa fa-pencil"></i>
        </button>

        <button className="pagination-buttons-save pagination-buttons ">
          <i className="fa fa-floppy-disk"></i>
        </button>
        <button className="pagination-buttons">
          <i className="fa fa-backward"></i>
        </button>

        <button onClick={() => exportToExcel()} className="action-buttons">
          Export to Excel
        </button>
        <button className="action-buttons" disabled>
          Show Normalized
        </button>
        <button className="next action-buttons" onClick={() => navigate("/")}>
          Submit{" "}
        </button>
      </div>
    </div>
  );
};

export default ActionBar;
