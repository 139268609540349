import "./Stepper.css";

const breadcrumbs: any = [
  { title: "Work Queue" },
  { title: "Document Classification" },
  { title: "Data Extraction" },
  { title: "Compliance Screening" },
  { title: "Documents Check" },
  { title: "Decision Summary" },
];

interface StepperProps {
  activeStepperIndex: number;
}

export const Stepper: React.FC<StepperProps> = ({ activeStepperIndex }) => {
  return (
    <div className="stepper">
      {breadcrumbs.map((breadcrumb: any, index: any) => (
        <a href="/">
          <span
            className={
              index === activeStepperIndex
                ? "active-stepper"
                : "inactive-stepper"
            }
          >
            <div>
              <span className="stepper-title">{breadcrumb.title}</span>
              <span className="arrow"></span>
              <span className="line"></span>
            </div>
          </span>
        </a>
      ))}
    </div>
  );
};
