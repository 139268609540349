import React, { useState } from "react";
import "./CheckboxTable.css";

const CheckboxTable = (tableData: any) => {
  // State to manage checked status of rows and columns
  const [checkedRows, setCheckedRows] = useState(
    Array.from({ length: tableData?.tableData?.headers }, () => false)
  );
  const [checkedColumns, setCheckedColumns] = useState(
    Array.from({ length: tableData?.tableData?.values }, () => false)
  );

  // Function to handle row checkbox toggle
  const toggleRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    updatedRows[rowIndex] = !updatedRows[rowIndex];
    setCheckedRows(updatedRows);
  };

  // Function to handle column checkbox toggle
  const toggleColumn = (colIndex: number) => {
    const updatedColumns = [...checkedColumns];
    updatedColumns[colIndex] = !updatedColumns[colIndex];
    setCheckedColumns(updatedColumns);
  };

  return (
    <table className="checkbox-table">
      <thead>
        <tr>
          {/* Empty cell at (0,0) */}
          <th style={{ backgroundColor: "#dcdcdc" }}></th>
          {/* Column headers with checkboxes */}
          {tableData?.tableData?.headers?.map((header: any, colIndex: any) => (
            <th key={colIndex}>
              <input
                type="checkbox"
                checked={checkedColumns[colIndex]}
                onChange={() => toggleColumn(colIndex)}
              />
              <span style={{ fontWeight: 400, fontSize: "12px" }}>All</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Table rows */}
        {tableData?.tableData?.values?.map((value: any, rowIndex: any) => (
          <tr key={rowIndex}>
            {/* Row header with checkbox */}
            <th>
              <div style={{ width: "100px" }}>
                <input
                  type="checkbox"
                  checked={checkedRows[rowIndex]}
                  onChange={() => toggleRow(rowIndex)}
                />
                <span style={{ fontWeight: 400, fontSize: "12px" }}>All</span>
              </div>
            </th>
            {/* Table cells */}
            {tableData?.tableData?.headers?.map(
              (header: any, colIndex: any) => (
                <td
                  key={`${rowIndex}-${colIndex}`}
                  className={
                    checkedRows[rowIndex] || checkedColumns[colIndex]
                      ? "highlighted"
                      : ""
                  }
                >
                  <div style={{ width: "100px" }}>{value[header]}</div>
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CheckboxTable;
