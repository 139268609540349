interface DefaultViewProps {
  hoveredKey: string;
  setHoveredKey: (hoveredKey: string) => void;
  label: any;
  index: number;
  handleDocumentLabelEdit: (
    extraction: any,
    label: any,
    innerText: any
  ) => void;
  setFocusedKey: (focusedKey: string) => void;
  focusedKey: string;
  setExtractedText: (extractedText: string) => void;
  handleCellEdit: (label: any, innerText: any) => void;
  deleteRow: (label: any) => void;
  extractedText: string;
  location: any;
  editedValues: any;
  currentDocument: number;
}

const DefaultView: React.FC<DefaultViewProps> = ({
  hoveredKey,
  setHoveredKey,
  label,
  index,
  handleDocumentLabelEdit,
  setFocusedKey,
  focusedKey,
  setExtractedText,
  handleCellEdit,
  deleteRow,
  extractedText,
  location,
  editedValues,
  currentDocument,
}) => {
  return (
    <tr
      onMouseLeave={() => {
        hoveredKey === label && setHoveredKey("");
      }}
      onMouseEnter={() => setHoveredKey(label)}
      key={`${label}-${index}`}
      className="document-viewer-row"
      onClick={() => {
        document.getElementById(`doc-preview-${index}-${currentDocument}-box`)?.scrollIntoView({ behavior: 'smooth' })
      }}
    >
      <td
        contentEditable
        suppressContentEditableWarning
        key={`${label}-${index}-doc`}
        onBlur={(e: any) => {
          handleDocumentLabelEdit(
            location.state.data[currentDocument].extraction,
            label,
            e.target.innerText
          );
        }}
      >
        {label.startsWith("new_key") ? "" : label}
      </td>
      <td key={`${label}-${index}-tradesun`}>
        {label.startsWith("new_key") ? "" : label}
      </td>

      <td
        contentEditable={true}
        onClick={() => setFocusedKey(label)}
        suppressContentEditableWarning
        key={`${label}-${index}-doc-val`}
        onBlur={(e) => {
          focusedKey === label && setFocusedKey("");
          setExtractedText("");
          handleCellEdit(label, e.target.innerText);
        }}
      >
        {extractedText !== "" && focusedKey === label
          ? extractedText
          : editedValues[label] ||
          location.state.data[currentDocument].extraction[label].value}
      </td>

      {hoveredKey === label && (
        <i
          className="fa fa-trash"
          key={`${label}-${index}-icon`}
          onClick={() => deleteRow(label)}
        ></i>
      )}
    </tr>
  );
};

export default DefaultView;
