import WorkQueue from "./components/WorkQueue/WorkQueue";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import NewTransactionForm from "./components/NewTransactionForm/NewTransactionForm";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import { Navbar } from "./components/Common/Navbar/Navbar";
import UploadAndAsk from "./components/UploadAndAsk/UploadAndAsk";

const router = createBrowserRouter([
  {
    path: "/",
    element: <WorkQueue />,
  },
  {
    path: "/new",
    element: <NewTransactionForm />,
  },
  {
    path: "/extraction",
    element: <DocumentViewer />,
  },
  {
    path: "/ask",
    element: <UploadAndAsk />,
  },
]);

function App() {
  return (
    <div className="App">
      <Navbar />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
