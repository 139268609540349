import { useState } from "react";
import "./UploadAndAsk.css";
import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "../Common/Loader/Loader";

const UploadAndAsk = () => {
  const navigate = useNavigate();
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const [formData, setFormData] = useState({
    referenceNo: "CHK-TS-Q-PC-280624",
    branch: "New York",
    product: "CRE",
    productSubType: "Extraction",
    transactionTypes: "Validation and Verification",
    instrumentRef: "CHK-TS-Q-PC-280624",
    transactionAmount: "",
    currency: "EURO",
    applicant: "",
    beneficiary: "",
    file: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e: any) => {
    setFormData({ ...formData, file: e.target.files[0] });
    setShowUploadButton(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData();

    data.append("file", formData.file);
    data.append("userPrompt", question);
    // const res = await axios.post("http://localhost:3001/extract/prompt", data);
    const res = await axios.post("https://ai-api.tradesun-dev.com/extract", data);
    setShowLoader(false);
    console.log(res.data);

    setAnswer(res.data.data.data)
  };

  return (
    <div className="new-transaction-form">
      {showLoader && <Loader />}
      <div style={styles.container}>
        <div style={styles.card}>
          <h2 style={styles.title}>Upload Document & Ask a Question</h2>
          <form onSubmit={handleSubmit} style={styles.form}>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.txt"
              style={styles.fileInput}
            />
            <input
              type="text"
              placeholder="Ask a question..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              style={styles.textInput}
            />
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
          {answer && (
            <div style={styles.answerSection}>
              <h3 style={styles.answerTitle}>Answer:</h3>
              <p style={styles.answerText}>{answer}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
// Custom Styles
const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f4f4f4',
  },
  card: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  title: {
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileInput: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    boxSizing: 'border-box',
  },
  textInput: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    boxSizing: 'border-box',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  submitButtonHover: {
    backgroundColor: '#0056b3',
  },
  answerSection: {
    marginTop: '20px',
    textAlign: 'left',
  },
  answerTitle: {
    fontSize: '20px',
    color: '#333',
  },
  answerText: {
    fontSize: '16px',
    color: '#555',
  },
};

export default UploadAndAsk;
