import { useState } from "react";
import "./NewTransactionForm.css";
import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "../Common/Loader/Loader";

const NewTransactionForm = () => {
  const navigate = useNavigate();
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [formData, setFormData] = useState({
    referenceNo: "CHK-TS-Q-PC-280624",
    branch: "New York",
    product: "CRE",
    productSubType: "Extraction",
    transactionTypes: "Validation and Verification",
    instrumentRef: "CHK-TS-Q-PC-280624",
    transactionAmount: "",
    currency: "EURO",
    applicant: "",
    beneficiary: "",
    file: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e: any) => {
    setFormData({ ...formData, file: e.target.files[0] });
    setShowUploadButton(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData();

    data.append("file", formData.file);
    data.append("docType", formData.product);
    if (formData.product !== 'LC') { data.append("classifyDoc", 'true'); }
    // const res = await axios.post("http://localhost:3001/extract", data);
    const res = await axios.post("https://ai-api.tradesun-dev.com/extract", data);
    setShowLoader(false);
    console.log(res.data);
    navigate("/extraction", {
      state: {
        data: res.data.data.map((r: any) => ({
          image: r.image,
          images: r.images,
          extraction: r.mappings,
          fileName: res.data.fileName,
          ocr: r.ocr,
          docType: r.docType,
        })),
      },
    });
  };

  return (
    <div className="new-transaction-form">
      {showLoader && <Loader />}
      <form onSubmit={handleSubmit}>
        <h4>
          If you want to capture any information related to the pictures being
          uploaded, these fields can be configured as per your requirement.
        </h4>
        <div className="form-row">
          <div className="form-group">
            <label>Reference No *</label>
            <input
              type="text"
              name="referenceNo"
              value={formData.referenceNo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Branch *</label>
            <input
              type="text"
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Product Type *</label>
            <select
              onChange={handleChange}
              name="product"
              id="product"
              value={formData.product}
            >
              {/* <option value={"CHK"}>CHK</option> */}
              <option value={"CRE"}>Commercial Real Estate</option>
              <option value={"ABL"}>Asset Based Lending</option>
              <option value={"DF"}>Distribution Finance</option>
              <option value={"MM"}>Middle Market</option>
              <option value={"LC"}>Letter of Credit</option>
              <option value={"CHK"}>Cheque</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Product Sub Type *</label>
            <input
              type="text"
              name="productSubType"
              value={formData.productSubType}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Transaction Type *</label>
            <select
              onChange={handleChange}
              name="transactionTypes"
              id="transactionTypes"
              value={formData.transactionTypes}
            >
              <option value={"CRE"}>Origination</option>
              <option value={"ABL"}>Underwriting</option>
              <option value={"DF"}>Closing</option>
            </select>
          </div>
          <div className="form-group">
            <label>Instrument Ref *</label>
            <input
              type="text"
              name="instrumentRef"
              value={formData.instrumentRef}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Transaction Amount</label>
            <div className="transaction-amount">
              <input
                type="number"
                name="transactionAmount"
                value={formData.transactionAmount}
                onChange={handleChange}
              />
              <select
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                {/* Add more currencies as needed */}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Applicant</label>
            <input
              type="text"
              name="applicant"
              value={formData.applicant}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Beneficiary</label>
            <input
              type="text"
              name="beneficiary"
              value={formData.beneficiary}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row buttons">
          <input type="file" onChange={handleFileChange} />
          {showUploadButton && (
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          )}

          <button onClick={() => navigate(-1)} type="button" className="btn btn-secondary">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewTransactionForm;
