interface TableViewProps {
  location: any;
  label: any;
  index: number;
  toggleTableAccordion: boolean;
  setToggleTableAccordion: (toggle: boolean) => void;
  setShowModal: (toggle: boolean) => void;
  setModalData: (data: any) => void;
  currentDocument: number;
}

const TableView: React.FC<TableViewProps> = ({
  location,
  label,
  index,
  toggleTableAccordion,
  setToggleTableAccordion,
  setShowModal,
  setModalData,
  currentDocument,
}) => {
  return (
    <>
      <tr key={`${label}`} className="document-viewer-row">
        <td
          contentEditable
          suppressContentEditableWarning
          key={`${label}-${index}-doc`}
        >
          <i
            className={
              toggleTableAccordion ? "fa fa-angles-down" : "fa fa-angles-right"
            }
            key={`${label}-${index}-chevron-icon`}
            onClick={() => setToggleTableAccordion(!toggleTableAccordion)}
          ></i>

          {label}
        </td>
        <td key={`${label}-${index}-tradesun`}>{label}</td>
        <td
          contentEditable={true}
          suppressContentEditableWarning
          key={`${label}-${index}-doc-val`}
        >
          ( Click to Expand )
        </td>
        {/* 
      <i
        className="fa fa-trash"
        key={`${label}-${index}-icon`}
        // onClick={() => deleteAccordionRow(label, i)}
      ></i> */}
      </tr>

      {toggleTableAccordion &&
        location.state.data[currentDocument].extraction[label]?.map(
          (table: any, i: number) => (
            <tr key={`${label}`} className="document-viewer-row">
              <td
                contentEditable
                suppressContentEditableWarning
                key={`${label}-${i}-doc`}
              >
                <i
                  className="fa fa-table"
                  key={`${label}-${i}-chevron-icon`}
                  onClick={() => {
                    setShowModal(true);
                    setModalData(table);
                  }}
                ></i>

                {`table-${i}`}
              </td>
              <td key={`${label}-${i}-tradesun`}>{`table-${i}`}</td>
              <td
                contentEditable={true}
                suppressContentEditableWarning
                key={`${label}-${i}-doc-val`}
              >
                {table.headers.slice(0, 4).join(",   ")}...
              </td>

              {/* <i
              className="fa fa-trash"
              key={`${label}-${index}-icon`}
              // onClick={() => deleteAccordionRow(label, i)}
            ></i> */}
            </tr>
          )
        )}
    </>
  );
};

export default TableView;
