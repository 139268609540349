import React from "react";
import "./HeaderTable.css"; // CSS file for styling

const HeaderTable = (tableData: any) => {
  const handleDelete = (columnIndex: number) => {
    // Implement delete functionality as needed
    console.log(`Deleting column ${columnIndex}`);
  };

  console.log(tableData);

  return (
    <table className="header-table">
      <thead>
        <tr>
          {/* First row headers */}
          <th>Document label</th>
          {tableData?.tableData?.headers?.map((header: any, colIndex: any) => (
            <th key={colIndex}>
              <div className="input-with-delete">
                <input
                  type="text"
                  placeholder="Input field"
                  defaultValue={header}
                />
                <i
                  className="fa fa-trash"
                  onClick={() => handleDelete(colIndex)}
                ></i>
              </div>
            </th>
          ))}
        </tr>
        <tr>
          {/* Second row headers */}
          <th>Tradesun label</th>
          {tableData?.tableData?.headers?.map((header: any, colIndex: any) => (
            <td key={colIndex}>
              <select>
                <option value="">{header}</option>
                <option value="">Dropdown Option 2</option>
                <option value="">Dropdown Option 3</option>
              </select>
            </td>
          ))}
        </tr>
      </thead>
    </table>
  );
};

export default HeaderTable;
