import { useState } from "react";

interface ThumbnailProps {
  imageData: any;
  setCurrentDocument: (currentDocument: number) => void;
  currentDocument: number;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  imageData,
  setCurrentDocument,
  currentDocument,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="thumbnail-container">
      <div className="accordion-item">
        <div
          className="accordion-title"
          onClick={toggleAccordion}
          style={{ cursor: "pointer" }}
        >
          <i
            className={
              isOpen ? "fa fa-angle-double-down" : "fa fa-angle-double-right"
            }
          ></i>
          <span> Thumbnails</span>
        </div>
        <div className="thumbnail-image-container">
          {isOpen &&
            imageData.map((doc: any, index: number) => (
              <div
                key={index}
                className={"thumbnail"}
                onClick={(e) => setCurrentDocument(index)}
              >
                {doc.docType === "CHK" ? (
                  <>
                    <img
                      alt=""
                      id="doc-thumbnail"
                      height={150}
                      width={160}
                      className="thumbnail-image"
                      src={`data:image/png;base64, ${doc.image}`}
                    />
                    <div
                      style={{
                        zIndex: 999,
                        height: 150,
                        width: 160,
                        position: "relative",
                        border:
                          index === currentDocument
                            ? "2px solid green"
                            : "1px solid #ddd",
                        backgroundColor:
                          index === currentDocument ? "rgba(0,0,0,0.2)" : "",
                      }}
                    ></div>
                  </>
                ) : (
                  <div
                    style={{
                      height: 150,
                      width: 160,
                      overflow: "scroll",
                      border:
                        index === currentDocument
                          ? "2px solid green"
                          : "1px solid #ddd",
                      backgroundColor:
                        index === currentDocument ? "rgba(0,0,0,0.2)" : "",
                    }}
                  >
                    {(doc.images as Array<any>).map(
                      (image: any, index: number) => (
                        <img
                          alt=""
                          className="thumbnail-pdf"
                          src={`data:image/png;base64, ${image}`}
                        />
                      )
                    )}
                  </div>
                )}

                <span className="thumbnail-doc-type">{doc.docType}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Thumbnail;
