import { useState } from "react";

interface Vertices {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface ImageContainerProps {
  imgRef: React.MutableRefObject<HTMLImageElement[]>;
  handleImageLoad: (e: any, pageNo: number) => void;
  handleMouseDown: (e: React.MouseEvent, pageNo: number) => void;
  handleMouseMove: (e: React.MouseEvent, pageNo: number) => void;
  imgSrc: string;
  imgDocType: string;
  selectionStart: any;
  selectionEnd: any;
  boxes: any[];
  calculateBoxStyle: (vertices: Vertices[], pageNo: number) => void;
  hoveredKey: string;
  setZoomCalled: React.Dispatch<React.SetStateAction<boolean>>;
  isZoomCalled: boolean;
  selectionPageNo?: number;
  location?: any;
  currentDocument: number;
}
const ImageContainer: React.FC<ImageContainerProps> = ({
  handleImageLoad,
  handleMouseDown,
  handleMouseMove,
  imgRef,
  imgSrc,
  selectionEnd,
  selectionStart,
  boxes,
  calculateBoxStyle,
  hoveredKey,
  imgDocType,
  setZoomCalled,
  isZoomCalled,
  location,
  currentDocument,
}) => {
  const [zoom, setZoom] = useState(1);

  const handleZoomChange = async (e: any) => {
    const zoomValue = Number(e.target.value);
    setZoomCalled(true);
    setZoom(zoomValue);

    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });

    const event = new Event("resize");
    window.dispatchEvent(event);
  };

  const imageWidth = isZoomCalled && zoom !== 1 ? `${zoom * 100}%` : "100%";
  const imageHeight = isZoomCalled ? zoom * 300 : 300;

  return (
    <div className="document-content">
      <div className="document-header">
        <h2>Page 1 : {imgDocType}</h2>
        <input
          type="range"
          min="1"
          max="3"
          step="0.1"
          value={zoom}
          onChange={handleZoomChange}
          className="zoom-slider"
        />
      </div>
      <div className="document-page">
        <div
          className="image-container"
          style={
            {
              position: "relative",
              display: "flex",
              overflow: "unset",
              height: "auto",
              width: "100%",
              flexDirection: "column",
            }

          }
        >
          {imgDocType === "CHK" ? (
            <>
              <img
                alt=""
                id={`doc-preview-0-${currentDocument}`}
                ref={(el) => (imgRef.current[0] = el!)}
                onLoad={(e) => handleImageLoad(e, 1)}
                height={imageHeight}
                width={imageWidth}
                onMouseDown={(e) => handleMouseDown(e, 1)}
                onMouseMove={(e) => handleMouseMove(e, 1)}
                className="main-image"
                src={`data:image/png;base64, ${imgSrc}`}
              />
              {boxes.map((box: any, index: number) => {
                let { top, left, height, width }: any = calculateBoxStyle(
                  box.vertices,
                  box.pageNo
                );

                return (
                  <div
                    key={index}
                    className="box"
                    style={{
                      top: `${top + 10 * (box.pageNo - 1)}px`,
                      left: `${left}px`,
                      width: `${width}px`,
                      height: `${height}px`,
                      padding: "10px",
                      background:
                        hoveredKey === box.id ? "#aaaaaaa3" : "rgba(51, 153, 255, 0.5)",
                    }}
                  ></div>
                );
              })}
            </>
          ) : (
            (location.state.data[currentDocument].images as Array<any>).map(
              (image: any, i) => (
                <>
                  <img
                    alt=""
                    key={`doc-preview-${i}`}
                    id={`doc-preview-${i}-${currentDocument}`}
                    ref={(el) => (imgRef.current[i] = el!)}
                    onLoad={(e) => handleImageLoad(e, i + 1)}
                    width={imageWidth}
                    height={isZoomCalled ? `${zoom * 100}%` : "100%"}
                    onMouseDown={(e) => {
                      handleMouseDown(e, i + 1);
                    }}
                    onMouseMove={(e) => handleMouseMove(e, 1)}
                    className="main-image"
                    src={`data:image/png;base64, ${image}`}
                  />
                  {boxes.map((box: any, index: number) => {
                    if (box.pageNo !== i + 1) return null;
                    let { top, left, height, width }: any = calculateBoxStyle(
                      box.vertices,
                      box.pageNo
                    );

                    return (
                      <div
                        key={index}
                        id={`doc-preview-${index}-${currentDocument}-box`}
                        className="box"
                        style={{
                          top: `${top}px`,
                          left: `${left}px`,
                          width: `${width}px`,
                          height: `${height}px`,
                          zIndex: hoveredKey !== box.id ? 1 : 2000,
                          background:
                            hoveredKey !== box.id ? "#aaaaaaa3" : "rgba(51, 153, 255, 0.5)",
                        }}
                      ></div>
                    );
                  })}
                </>
              )
            )
          )}
          {selectionStart && selectionEnd && (
            <div
              style={{
                position: "absolute",
                left: Math.min(selectionStart.x, selectionEnd.x),
                top: Math.min(selectionStart.y, selectionEnd.y),
                width: Math.abs(selectionEnd.x - selectionStart.x),
                height: Math.abs(selectionEnd.y - selectionStart.y),
                pointerEvents: "none",
                background: "rgba(51, 153, 255, 0.5)",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ImageContainer;
